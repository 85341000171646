import React, { memo } from 'react';
import { SEO } from '@components';

import './enterprise-app-development.scss';
import {
  backendTechnologies,
  frontendTechnologies,
  mobileTechnologies,
  overviewItems,
  oodenQA,
} from '@content';
import { LandingTechnology, LandingOverview, LandingWork } from '@containers';

const QualityAssurance = () => (
  <div>
    <SEO title="Quality Assurance" />
    <div className="on-wwd-main">
      <div className="on-wwd-container">
        <div className="on-wwd-text">
          <h1>Quality Assurance</h1>
          <p>
            Our QA experts work hard to ensure our processes and the final product meet the demands and expectations of our clients.
          </p>
          <p>
          Software testing is at the core of quality assurance. All development projects are covered with their own testing strategy.
          We believe quality assurance is about being flexible and doing whatever we can to get the best software in front of our clients.  
          </p>
        </div>
      </div>
      <div className="on-wwd-text on-wwd-item">
        <h1>Why ooden ?</h1>
        <LandingOverview text="" items={oodenQA} />
      </div>
      <LandingTechnology text="Backend" items={backendTechnologies} />
      <LandingTechnology text="Frontend" items={frontendTechnologies} />
      <LandingTechnology text="Mobile" items={mobileTechnologies} />
      <div className="on-wwd-text"><h1>{' '}</h1>
        <LandingWork text="All you need is the right partner. We can be that partner." />
        
        <LandingOverview text="How we can generally help" items={overviewItems} />
      </div>

      <div className="on-wwd-padding" />
    </div>
  </div>
);

export default memo(QualityAssurance);
